/** @jsxImportSource theme-ui */
import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  PrismicImprintPageDataType,
  PrismicImprintPageContactDataGroupType,
  PrismicImageType,
  Maybe,
} from 'root/graphql-types'
import { H2, H6, Body10Cols } from 'src/components/atoms/text'
import { Grid } from 'src/components/atoms/layout'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { motion } from 'framer-motion'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
} from 'src/animations'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'

type ImprintPageProps = {
  pageContext: PrismicImprintPageDataType & {
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
  }
  path: string
}

const ContactBlock: FC<{
  line1?: Maybe<string>
  line2?: Maybe<string>
  line3?: Maybe<string>
}> = ({ line1, line2, line3 }) => {
  return (
    <div sx={{ mb: ml('xl', 'xxl') }}>
      {line1 && <H6 sx={{ mb: ml('xs', 's') }}>{line1}</H6>}
      {line2 && (
        <Body10Cols sx={{ mb: ml('m', 'l'), whiteSpace: 'pre-line' }}>
          {line2}
        </Body10Cols>
      )}
      {line3 && <H2>{line3}</H2>}
    </div>
  )
}

const ContactGrid: FC<{
  data?: Maybe<Array<Maybe<PrismicImprintPageContactDataGroupType>>>
}> = ({ data }) => {
  if (!data) return null
  return (
    <Grid columns={ml(1, 2)}>
      {_.map(data, (lines) => {
        const { line_1 = '', line_2 = '', line_3 = '' } = lines || {}
        return (
          <ContactBlock
            line1={line_1 || 'LINE 1'}
            line2={line_2}
            line3={line_3}
          />
        )
      })}
    </Grid>
  )
}

const ImprintPage: FC<ImprintPageProps> = ({ pageContext, path }) => {
  const { title, content, contact_data } = pageContext

  const contentHtml = useMemo(
    () => replaceHtml(content?.html || '', 'p', Body10Cols),
    [content?.html]
  )

  setActiveLink(null)
  return (
    <div>
      <SEO
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <AnimationScrollYFix />
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <H2 sx={{ mt: ml('s', 'l'), mb: ml('m', 'xl') }}>{title}</H2>
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        <ContactGrid data={contact_data} />
        <div sx={{ mb: 'xl' }}>{contentHtml}</div>
      </motion.div>
    </div>
  )
}

export default ImprintPage
